import React from 'react'
import classes from './BuildControls.module.css'
import BuildControl from './BuildControl/BuildControl'

const buildControls = (props) => {
    
    const controls = [
        { label: 'Salad', type: 'salad'},
        { label: 'Bacon', type: 'bacon'},
        { label: 'Cheese', type: 'cheese'},
        { label: 'Meat', type: 'meat'},
       

    ];

    const controlComponents = controls.map(ctrl => <BuildControl 
                                                        key={ctrl.label} 
                                                        label={ctrl.label}
                                                        added={() => props.ingredientAdded(ctrl.type)}
                                                        removed={() => props.ingredientRemoved(ctrl.type)}
                                                        disabled={props.disabled[ctrl.type]}  />)
    
    return (
        <div className={classes.BuildControls}>
            <p>Current Price: <strong>{props.price.toFixed(2)}</strong></p>
            {controlComponents}    
            <button 
                className={classes.OrderButton}
                onClick={props.ordered}
                disabled={!props.purchasable}>{props.isAuth ? "ORDER NOW" : "SIGN UP TO ORDER"}</button>  
        </div>
    )
}

export default buildControls;
