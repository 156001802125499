import React, { Component } from 'react'
import Button from '../../components/UI/Button/Button'
import Input from '../../components/UI/Input/Input'
import Spinner from '../../components/UI/Spinner/Spinner'
import classes from './Auth.module.css'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import {checkValidity} from '../../shared/utility'


class Auth extends Component {
    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }, 
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }, 
        },
        isSignup: true
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== "/" && !this.props.isBuilding) {
            this.props.onSetAuthRedirectPath();
            
        }
    }


    
    
    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        }

        this.setState({controls: updatedControls})
        
      
    }

    submitHandler = (event) => {
        event.preventDefault();
        
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value, this.state.isSignup);
    }

    switchAuthModeHandler = () => {
        console.log("Switching");
        this.setState(prevState => {
            return {isSignup: !prevState.isSignup}
        })
    }
    render() {
        const formElements = [];
        for (let key in this.state.controls) {
            let e = this.state.controls[key];
            let formElement = <Input 
                                key={key}
                                name={key} 
                                elementType={e.elementType} 
                                elementConfig={e.elementConfig} 
                                value={e.value} 
                                changed={(event) => this.inputChangedHandler(event,key)}
                                invalid={!e.valid}
                                shouldValidate={e.validation}
                                touched={e.touched} />
            formElements.push(formElement);
        }
        let form = (
            <form onSubmit={this.submitHandler}>
                {formElements}
                <Button btnType="Success">{this.state.isSignup ? "SIGN UP" : "SIGN IN"}</Button>
            </form>
            
        );
        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = ( <p>{this.props.error.message}</p>)
        }
        let redirect = null;
        if (this.props.isAuth) {
            redirect = <Redirect to={this.props.authRedirectPath} />
        }
       

        return (
            <div className={classes.Auth}>
            { redirect} 
                {errorMessage}
                {form}
                <Button 
                    btnType="Danger"
                    clicked={this.switchAuthModeHandler}>
                        SWITCH TO {this.state.isSignup ? "SIGN IN" : "SIGN UP"}
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading ,
        error: state.auth.error,
        isAuth: state.auth.token !== null,
        isBuilding: state.burger.building,
        authRedirectPath: state.auth.authRedirectPath
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, isSignup) => {dispatch(actions.auth(email,password, isSignup))},
        onSetAuthRedirectPath: () => {dispatch(actions.setAuthRedirectPath("/"))}
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(Auth);
