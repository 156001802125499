import React, { useEffect, Suspense} from 'react';
import Layout from './hoc/Layout/Layout'
import BurgerBuilder from './containers/BurgerBuilder/BurgerBuilder'
import Checkout from './containers/Checkout/Checkout'
import { Route, Switch, Redirect } from 'react-router-dom'

import Auth from './containers/Auth/Auth'
import Logout from './containers/Auth/Logout/Logout'
import {connect} from 'react-redux'
import * as actions from './store/actions/index'

const Orders = React.lazy(() => import('./containers/Orders/Orders'));

function App(props) {
  useEffect (() => {
    props.onCheckAuthState();
  },[])

  let routes = (
    <Switch>
      <Route path="/auth" exact component={Auth} />
      <Route path="/" exact component={BurgerBuilder} />
      <Redirect to="/" />
    </Switch>
  );

  if ( props.isAuth) {
    routes = (
       <Switch>
          <Route path="/checkout" component={Checkout} />
          <Route path="/orders" render={() => (
            <Suspense fallback={<div>Loading...</div>}>
              <Orders />
            </Suspense> 
            )} />
          
          <Route path="/logout"        component={Logout} />
          <Route path="/auth" exact component={Auth} />
          <Route path="/" exact component={BurgerBuilder} />
          <Redirect to="/" />
      </Switch>
    )
  }

  return (
    <div>
      <Layout>
        {routes}
      
        
      </Layout>
    </div>
    

    
  );
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckAuthState: () => {dispatch(actions.authCheckState())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
