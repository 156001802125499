import React from 'react'
import { withRouter} from 'react-router-dom'
import classes from './Burger.module.css'
import BurgerIngredient from './BurgerIngredient/BurgerIngredient'

const burger = props => {

    
    // const transformedIngredients = Object.keys(props.ingredients)
    //     .map(igKey => {
    //         return [...Array(props.ingredients[igKey])].map((_, i) => {
    //             return <BurgerIngredient key={igKey + i} type={igKey} />
    //         });
    //     })
    //     .reduce((arr,el) => {
    //         return arr.concat(el) 
    //     },[]);

    let transformedIngredients = [];
    for (let [key, value] of Object.entries(props.ingredients)) {
        for (let x=0;x<value;x++) {
            transformedIngredients.push(<BurgerIngredient key={key + x} type={key} />);
        }
    }

    //console.log("Entries:",Object.entries(props.ingredients));


    if (transformedIngredients.length === 0) {
        transformedIngredients = <p>Please Start Adding ingredients!</p>
    }
    return (
        
        <div className={classes.Burger}>
            <BurgerIngredient type="bread-top" />
            {transformedIngredients}
            <BurgerIngredient type="bread-bottom" />
        </div>
    )
}

burger.propTypes = {

}

export default withRouter(burger);
