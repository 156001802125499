import React, {useEffect} from 'react'
import classes from './NavigationItems.module.css'
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'


export const NavigationItems = (props) => {
    /* useEffect (() => {
        if (props.isAuth) {
            props.fetchOrders(props.token);
        }
        
    },[]) */
    
    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/" exact >Burger Builder</NavigationItem>
            { props.isAuth ? <NavigationItem link="/orders" badgeContent={props.orders.length}>Orders</NavigationItem> : null }
            { props.isAuth ? <NavigationItem link="/logout">Logout</NavigationItem>  : <NavigationItem link="/auth" exact >Login</NavigationItem> }
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        orders: state.order.orders,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (token) => {dispatch(actions.fetchOrders(token))}
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavigationItems);
