import * as actionTypes from '../actions/actionTypes'


const INGREDIENT_PRICES = {
    salad: 0.5,
    cheese: 0.4,
    meat: 1.3,
    bacon: 0.7
}

const initialState = {
    ingredients: null,
    totalPrice: 4,
    error: false,
    building: false
}

const addIngredient = (state, action) => {
    const updatedCount = state.ingredients[action.ingredientType] + 1;
        const updatedIngredients = {
            ...state.ingredients
        }
        updatedIngredients[action.ingredientType] = updatedCount;
        const priceAddition = INGREDIENT_PRICES[action.ingredientType];
        const newPrice = state.totalPrice + priceAddition;
        return {...state,
            totalPrice: newPrice,
            ingredients: updatedIngredients,
            building: true
        }
}

const removeIngredient = (state, action) => {
    let newCount = state.ingredients[action.ingredientType] - 1;
    if (newCount < 0) {
        return state;
    }
    const newIngredients = {
        ...state.ingredients
    }
    newIngredients[action.ingredientType] = newCount;
    const price = INGREDIENT_PRICES[action.ingredientType];
    const updatedPrice = state.totalPrice - price;
    return {
        ...state,
        totalPrice: updatedPrice,
        ingredients: newIngredients,
        building: true
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_INGREDIENTS:          return { ...state, ingredients: action.ingredients, error: false, totalPrice: 4, building: false}
        case actionTypes.FETCH_INGREDIENTS_FAILED: return { ...state, error: true}
        case actionTypes.ADD_INGREDIENT:           return addIngredient(state,action);
        case actionTypes.REMOVE_INGREDIENT:        return removeIngredient(state, action)
        default:
            return state;
    }
   
}

export default reducer;