import React, { Component } from 'react'
import Button from '../../../components/UI/Button/Button'
import classes from './ContactData.module.css'
import axios from '../../../axios-orders'
import Spinner from '../../../components/UI/Spinner/Spinner'
import Input from '../../../components/UI/Input/Input'
import { connect } from 'react-redux' 
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import * as actions  from '../../../store/actions/index'
import {checkValidity} from '../../../shared/utility'



class ContactData extends Component {
    state = {
        orderForm: {
           name: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Your Name'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           street: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Street'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           postalCode: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Postal Code'
               },
               value: '',
               validation: {
                   required: true,
                   minLength: 6,
                   maxLength: 6
               },
               valid: false,
               touched: false
           },
           province: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Province'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
           country: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Country'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           email: {
               elementType: 'input',
               elementConfig: {
                   type: 'email',
                   placeholder: 'Your Email'
               },
               value: '',
               validation: {
                   required: true,
                   isEmail: true
               },
               valid: false,
               touched: false
           },
           deliveryMethod: {
               elementType: 'select',
               elementConfig: {
                   options: [{value: 'fastest', displayValue: 'Fastest'},
                            {value: 'cheapest', displayValue: 'Cheapest'}]
               },
               value: 'fastest',
               valid: true
              
           },
            
           
        },
        formIsValid: false
        
    }

    orderHandler = (e) => {
        e.preventDefault();
        console.log(this.props.ingredients);
         
        //alert("You continue!");
        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
           formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value
        }
        
        const order = {
            ingredients: this.props.ingredients,
            price: this.props.totalPrice,
            userId: this.props.userId,
            orderData: formData
           
        }

        this.props.onOrderBurger(order, this.props.token);
        console.log("[ContactData.js] order:", order);
        


    }

   
    
    inputChangedHandler = (event, inputIdentifier) => {
        //console.log(event.target.value, " Entered on: ", inputIdentifier)
        //const updatedOrderForm =  {...this.state.orderForm};
        const updatedFormElement = {
            ...this.state.orderForm[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value,this.state.orderForm[inputIdentifier].validation),
            touched: true};
        
        const updatedOrderForm = {
            ...this.state.orderForm,
            [inputIdentifier]: updatedFormElement
        }
        
        let formIsValid = true;
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid
        }
        console.log(formIsValid);
        //console.log(updatedFormElement);
        this.setState({orderForm: updatedOrderForm, formIsValid: formIsValid})

    }

    componentDidMount() {
        console.log('[ContactData.js] props', this.props)
    }
    
    render() {
        const formElements = [];
        for (let key in this.state.orderForm) {
            let e = this.state.orderForm[key];
            let formElement = <Input 
                                key={key}
                                name={key} 
                                elementType={e.elementType} 
                                elementConfig={e.elementConfig} 
                                value={e.value} 
                                changed={(event) => this.inputChangedHandler(event,key)}
                                invalid={!e.valid}
                                shouldValidate={e.validation}
                                touched={e.touched} />
            formElements.push(formElement);
        }
        let form = (
            <form onSubmit={this.orderHandler}>
                {formElements}
                <Button btnType="Success" disabled={!this.state.formIsValid}>ORDER</Button>
            </form>
        );
        if (this.props.loading) {
            form = <Spinner />
        }
        return (
            <div className={classes.ContactData}>
                <h4>Enter your Contact Data</h4>
                {form}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ingredients: state.burger.ingredients,
        totalPrice: state.burger.totalPrice,
        loading: state.order.loading,
        token: state.auth.token,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOrderBurger: (orderData, token) => {dispatch(actions.purchaseBurger(orderData, token))}
    }
    
}

export default connect(mapStateToProps,mapDispatchToProps)(withErrorHandler(ContactData,axios));
